import axios from 'axios';

const API_BASE_URL = 'https://cnt.ae/api';

const apiService = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor to include token if available
apiService.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const login = async (credentials) => {
  return apiService.post('/login', credentials);
};

export const fetchProjects = async () => {
  return apiService.post('/getMyPostProject');
};

export const fetchCode = async (projectId) => {
  return apiService.post(`/view-dwtc-project-pin`, { 'project_id':projectId });
};

export default apiService;
