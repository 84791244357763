// src/controllers/UserController.js
import { login as apiLogin } from '../services/apiService';
import LoginForm from '../components/LoginForm';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { routes } from '../constants/routesConstants';
import { useAuth } from '../context/AuthContext';

const LoginController = () => {
  const navigate = useNavigate();
  const { login } = useAuth(); // Call useAuth inside the component

  const handleLogin = async (credentials) => {
    try {
      const response = await apiLogin(credentials); // Use the login function from apiService
      if (response.data) {
        const token = response.data.data[0].Token;
        login(token); // Set token in AuthContext
        localStorage.setItem('token', token ?? null); // Store token in localStorage
        toast.success('Login successful!');

        // Navigate to the projects page with token in state
        navigate('/projects', { state: { token } });
      }
    } catch (error) {
      console.error('Login failed', error);
      toast.error('Login failed! Please check your credentials.');
    }
  };

  // Pass credentials to handleLogin
  return <LoginForm onSubmit={handleLogin} />;
};

export default LoginController;
