import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { MDBBtn, MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBInput } from 'mdb-react-ui-kit';


const LoginForm = ({ onSubmit }) => {
  const [credentials, setCredentials] = useState({ email: '', password: '',fcm_token: 'Test',from:"reactApp" });


  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(credentials);
  };

  return (
    <MDBContainer fluid style={{ maxHeight: '100vh', overflow: 'hidden' }}>
      <MDBRow className="h-100">
        <MDBCol sm='6' className="d-flex align-items-center justify-content-center">
          <MDBCard className='my-5 cascading-right' style={{ background: 'hsla(0, 0%, 100%, 0.55)', backdropFilter: 'blur(30px)' }}>
            <MDBCardBody className='p-5 shadow-5 text-center'>
              <div className='d-flex flex-column justify-content-center h-custom-2 w-75 pt-4'>
                <div className='fw-normal mb-3 ps-5 pb-3'>
                  <img src="https://cnt.ae/front/images/new_logo.png" alt="logo" />
                </div>
                <h3 className="fw-normal mb-3 ps-5 pb-3" style={{ letterSpacing: '1px' }}>Log in</h3>
                <Form onSubmit={handleSubmit}>
                  <MDBInput
                    wrapperClass='mb-4 mx-5 w-100'
                    label='Email address'
                    id='email'
                    type='email'
                    size="lg"
                    value={credentials.email}
                    onChange={(e) => setCredentials({ ...credentials, email: e.target.value })}
                    required
                  />
                  <MDBInput
                    wrapperClass='mb-4 mx-5 w-100'
                    label='Password'
                    id='password'
                    type='password'
                    size="lg"
                    value={credentials.password}
                    onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
                    required
                  />
                  {/* {error && <div className="text-danger">{error}</div>} */}
                  <MDBBtn className="mb-4 px-5 mx-5 w-100" style={{ backgroundColor: '#909090' }} size='lg' type="submit">
                    Login
                  </MDBBtn>
                </Form>
              </div>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol sm='6' className='d-none d-sm-block px-0'>
          <img src="../../signup-bg.jpg" alt="Login image" className="h-100 w-100" style={{ objectFit: 'cover', objectPosition: 'left' }} />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default LoginForm;
