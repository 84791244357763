import React, { useState, useEffect } from 'react';
import { getProjects, startCodeFetchInterval } from '../controllers/Project/ProjectController';
import CodeDisplay from './CodeDisplay';
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from 'mdb-react-ui-kit';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody } from 'mdb-react-ui-kit';
import { Form } from 'react-bootstrap';
import { useAuth } from '../context/AuthContext';
import { toast } from 'react-toastify'; // Import toast for error notifications

const ProjectSelection = ({ onSubmit }) => {
    const { isAuthenticated } = useAuth();

    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState('');
    const [code, setCode] = useState('');
    const [codeFetchInterval, setCodeFetchInterval] = useState(null); // For managing the interval

    // Fetch projects when the component mounts
    useEffect(() => {
        const fetchProjects = async () => {
            try {
                await getProjects(setProjects);
            } catch (error) {
                console.error('Error fetching projects:', error);
                toast.error('Failed to fetch projects. Please try again.'); // Show error notification
            }
        };
        fetchProjects();
    }, []);

    // Manage the fetching of code based on selected project
    useEffect(() => {
        // Clear previous interval when project changes
        if (codeFetchInterval) {
            clearInterval(codeFetchInterval);
        }

        if (selectedProject) {
            const interval = startCodeFetchInterval(selectedProject, setCode);
            setCodeFetchInterval(interval); // Store the interval ID for clearing later
        }

        return () => {
            // Clear the interval on component unmount
            if (codeFetchInterval) {
                clearInterval(codeFetchInterval);
            }
        };
    }, [selectedProject]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!selectedProject) {
            toast.warn('Please select a project before submitting.'); // Warning notification
            return;
        }
        onSubmit(selectedProject); // Submit the selectedProject
    };

    return (
        <div>
            {/* {isAuthenticated ? ( */}
                <MDBContainer fluid className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh', overflow: 'hidden' }}>
                    <MDBRow className="justify-content-center w-100">
                        <MDBCol xs="12" sm="10" md="8" lg="6" xl="5" className="d-flex align-items-center justify-content-center">
                            <MDBCard className="cascading-right my-5 w-100">
                                <MDBCardBody className="p-4 p-md-5 shadow-5 text-center">
                                    <div className="d-flex flex-column justify-content-center h-custom-2 w-100 pt-4">
                                        <div className="fw-normal mb-3 pb-3">
                                            <img src="https://cnt.ae/front/images/new_logo.png" alt="Logo" className="img-fluid" />
                                        </div>
                                        <h3 className={`fw-normal mb-3 pb-3 ${code ? 'd-none' : ''}`} style={{ letterSpacing: '1px' }}>Select Project</h3>
                                        <Form onSubmit={handleSubmit}>
                                            <MDBDropdown className={`fw-normal mb-3 pb-3 ${code ? 'd-none' : ''}`}>
                                                <MDBDropdownToggle color='dark'>
                                                    {selectedProject && projects.length > 0
                                                        ? projects.find(project => project.id === selectedProject)?.name || 'Select Project'
                                                        : 'Select Project'}
                                                </MDBDropdownToggle>
                                                <MDBDropdownMenu>
                                                    <MDBDropdownItem onClick={() => setSelectedProject('')}>
                                                        Select Project
                                                    </MDBDropdownItem>
                                                    {projects.length > 0 ? (
                                                        projects.map((project) => (
                                                            <MDBDropdownItem key={project.project_id} onClick={() => setSelectedProject(project.project_id)}>
                                                                {project.project_name}
                                                            </MDBDropdownItem>
                                                        ))
                                                    ) : (
                                                        <MDBDropdownItem disabled>No projects available</MDBDropdownItem>
                                                    )}
                                                </MDBDropdownMenu>
                                            </MDBDropdown>
                                            {selectedProject && <CodeDisplay code={code} />}
                                        </Form>
                                    </div>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            {/* // ) : (
            //     <p>Please log in.</p>
            // )} */}
        </div>
    );
};

export default ProjectSelection;
