// src/controllers/ProjectController.js
import React from 'react';
import ProjectSelection from '../../components/ProjectSelection';
import { fetchProjects, fetchCode } from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const getProjects = async (setProjects) => {
    try {
        const response = await fetchProjects();
        setProjects(response.data.data);
    } catch (error) {
        console.error('Error fetching projects', error);
        toast.error('Error fetching projects. Please try again later.'); // Error notification
    }
};
export const startCodeFetchInterval = async (projectId, setCode) => {
    try {
        // First fetch immediately without waiting
        const response = await fetchCode(projectId);
        const formattedCode = formatCode(response.data.pin);
        setCode(formattedCode);
        
        // Now start the interval to fetch every 60 seconds
        return setInterval(async () => {
            try {
                const response = await fetchCode(projectId);
                const formattedCode = formatCode(response.data.pin);
                setCode(formattedCode);
            } catch (error) {
                console.error('Error fetching code', error);
                toast.error('Error fetching code. Please try again later.');
            }
        }, 20000); // Fetch every minute
    } catch (error) {
        console.error('Error fetching code', error);
        toast.error('Error fetching code. Please try again later.');
    }
};

// Helper function to format the code as A-B-C-D
const formatCode = (code) => {
    return code.split('').join('-');
};

const ProjectController = () => {
    const navigate = useNavigate(); // Get navigate function from useNavigate hook

    return (
        <ProjectSelection onSubmit={(data) => startCodeFetchInterval(data, navigate)} />
    );
};

export default ProjectController;
