// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProjectController from './controllers/Project/ProjectController';
import LoginController from './controllers/LoginController';
import { ToastContainer } from 'react-toastify';
import { AuthProvider } from './context/AuthContext'; // Import AuthProvider
import PrivateRoute from './components/PrivateRoute'; // Import PrivateRoute
import routes from "./constants/routesConstants";

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css"; // Optional: Font Awesome icons

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Public Route */}
          <Route path={routes.Login} element={<LoginController />} />

          {/* Private Routes */}
          <Route 
            path={routes.Projects} 
            element={
              <PrivateRoute>
                <ProjectController />
              </PrivateRoute>
            } 
          />
          <Route 
            path={routes.ProjectPin} 
            element={
              <PrivateRoute>
                <ProjectController />
              </PrivateRoute>
            } 
          />
        </Routes>
        <ToastContainer /> {/* Move ToastContainer outside of Routes */}
      </Router>
    </AuthProvider>
  );
};

export default App;
