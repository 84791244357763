// import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody } from 'mdb-react-ui-kit';

const CodeDisplay = ({ code }) => {

    
    return (
        <div>
            <h2 className="fw-bold mb-2 mt-5 text-uppercase fw-normal">Pin</h2>
            <h1 className="fw-bold mb-2 mt-5 text-uppercase fw-normal mb-3 ps-3 pb-3">{code ? `${code}` : 'Fetching code...'}</h1>
    </div>);
    // return <div>{code ? `Code: ${code}` : 'Fetching code...'}</div>;
};

export default CodeDisplay;
